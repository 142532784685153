body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background-color: #181D27 !important;
  background: #181D27;
  color: #AAB3CA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-input {
  background-color: #181D27 !important;
  color: #AAB3CA !important;
  border-width: 0px !important;
  height: 96px !important;
  margin-bottom: 5px;
  font-size: 35px;
  border-radius: 11px !important;
}
