.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.assetLogo {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 1px;
  font-size: 16px;
  font-weight: bold;
  color: #d6dbe5;
}

.assetLogo span {
  margin-left: -10px;
}

.assetLogo img,
.logo {
  width: 30px;
  height: 30px;
  padding-right: 20px;
}

header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  width: 90%;
}

.inputGroup {
  position: relative;
  margin-bottom: 20px;
}

.inputWrapper input::placeholder {
  color: grey;
}

.vaiInput {
  background-color: transparent !important;
  border: 1px solid #3a4157 !important;
}

label {
  display: block;
  text-align: left;
  font-weight: bold;
  margin-bottom: 8px;
}

.leftH,
.rightH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mainlogo {
  width: 250px;
  height: auto;
  padding-left: 20px;
}

.mainWindow {
  flex: 1;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tradeBox {
  align-items: flex-start;
  width: 100%;
  background-color: #1E2431;
  min-height: 350px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 420px;
  box-sizing: border-box;
}

.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.tradeBoxHeader h4 {
  margin: 1px 0 15px;
  font-size: 18px;
  text-align: left;
}

.walletBalance {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  color: #AAB3CA;
}

/* Buttons */

.connectButton,
.swapButton {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
  transition: 0.3s;
}

.connectButton {
  background-color: #3A78FF;
  color: #ffffff;
}

.connectButton:hover {
  cursor: pointer;
  background-color: #265ACC;
}

.swapButton {
  background-color: #3A78FF;
  width: 100%;
  font-size: 18px;
  color: #ffffff;
  margin: 8px 0 1px;
  padding: 15px 0;
  height: auto;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #265ACC;
}

.swapButton[disabled] {
  opacity: 0.4;
  color: #ffffff;
  background-color: #265ACC;
  cursor: not-allowed;
}

.switchButton {
  border: 1px solid #3a4157;
  margin: 10px auto;
  background-color: #3a4157;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  color: #5F6783;
  font-size: 15px;
  transition: 0.3s;
}

.switchButton:hover {
  border: 1px solid #3A78FF;
  color: white;
  cursor: pointer;
}

/* Footer */

.footer-container {
  background: #1E2431;
  padding: 15px 50px;
}

.footer-center {
  text-align: center;
}

.footer-buttons .ant-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #3a4157;
  border: 1px solid #3a4157;
  color: #5F6783;
  transition: 0.3s;
  margin-bottom: 15px;
}

.footer-buttons .ant-btn:hover {
  border: 1px solid #3A78FF;
  cursor: pointer;
}

.footer-buttons .ant-btn:hover .anticon-github {
  color: white;
}

.footer-buttons .ant-btn:hover .anticon-twitter {
  color: #1DA1F2;
}

.footer-buttons .ant-btn:hover .anticon-book {
  color: #9CB9FF;
}

.footer-buttons .ant-btn:hover .anticon-info-circle {
  color: white;
}

.footer-love {
  color: red;
}

.footer-primary {
  color: #AAB3CA;
}

.gradient-text {
  background: linear-gradient(90deg, #49EBBF, #20BDFF, #4068FF) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  font-weight: bold !important;
}

/* Modal */

.ant-modal-root .ant-modal-content {
  background-color: #1E2431 !important;
}

.ant-modal-root .ant-modal-header {
  background-color: #1E2431 !important;
}

.ant-modal-root .ant-modal-title {
  color: #AAB3CA !important;
}

.ant-modal-root .ant-modal-close-x {
  color: #AAB3CA !important;
}

.ant-modal-root .ant-modal-body {
  color: #AAB3CA !important;
  background-color: #1E2431 !important;
}

.ant-modal-root .ant-modal-footer button {
  background-color: #3A78FF !important;
  border: none !important;
  color: #ffffff !important;
}

.ant-modal-root .ant-modal-footer button:hover {
  background-color: #265ACC !important;
}

/* Mobile */

@media (max-width: 440px) {
  header {
    padding-right: 10px;
  }

  .mainlogo {
    width: 200px;
    height: auto;
    padding-left: 10px;
  }

  .connectButton {
    padding: 10px 10px;
    font-size: 14px;
  }

  .tradeBoxHeader h4 {
    font-size: 16px;
  }
}